import React, { useState, useCallback, ChangeEvent, FormEvent } from 'react';
import { X, PlusCircle } from 'lucide-react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { motion, AnimatePresence } from 'framer-motion';

interface CreateListFormProps {
  onClose: () => void;
  onCreateList: (list: ListData) => void;
}

interface DecodedToken {
  user: {
    id: string;
  };
}

interface ListData {
  title: string;
  description: string;
  isPrivate: boolean;
  listType: string;
  category: string;
}

interface FormInputProps {
  label: string;
  id: string;
  type?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
}

interface FormTextAreaProps {
  label: string;
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

interface FormSelectProps {
  label: string;
  id: string;
  options: Array<{ value: string; label: string; } | string>;
  value: string;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
}

interface AlertProps {
  message: string;
}

const categories = ['Genel', 'Alışveriş', 'Kitaplar', 'Yemek', 'Seyahat', 'Fitness', 'Müzik', 'Filmler'];
const listTypes = [
  { value: 'todo', label: 'Yapılacaklar' },
  { value: 'ranking', label: 'Sıralama' },
  { value: 'general', label: 'Genel' },
  { value: 'link', label: 'Link' },
];

const FormInput: React.FC<FormInputProps> = ({ label, id, type = 'text', value, onChange, required = false }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-200 mb-1">{label}</label>
    <input 
      type={type} 
      id={id} 
      name={id} 
      required={required}
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 bg-white bg-opacity-20 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent placeholder-indigo-300 text-white"
    />
  </div>
);

const FormTextArea: React.FC<FormTextAreaProps> = ({ label, id, value, onChange }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-200 mb-1">{label}</label>
    <textarea 
      id={id} 
      name={id} 
      rows={3}
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 bg-white bg-opacity-20 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent placeholder-indigo-300 text-white"
    ></textarea>
  </div>
);

const FormSelect: React.FC<FormSelectProps> = ({ label, id, options, value, onChange }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-200 mb-1">{label}</label>
    <select
      id={id}
      name={id}
      value={value}
      onChange={onChange}
      className="w-full px-3 py-2 bg-white bg-opacity-20 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent text-white"
    >
      {options.map((option) => (
        <option key={typeof option === 'string' ? option : option.value} value={typeof option === 'string' ? option : option.value}>
          {typeof option === 'string' ? option : option.label}
        </option>
      ))}
    </select>
  </div>
);

const Alert: React.FC<AlertProps> = ({ message }) => (
  <motion.div 
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    className="bg-red-500 bg-opacity-20 border border-red-400 text-red-100 px-4 py-3 rounded-md mb-4" 
    role="alert"
  >
    <strong className="font-bold">Hata! </strong>
    <span className="block sm:inline">{message}</span>
  </motion.div>
);

const CreateListForm: React.FC<CreateListFormProps> = ({ onClose, onCreateList }) => {
  const [formData, setFormData] = useState<ListData>({
    title: '',
    description: '',
    isPrivate: false,
    listType: 'todo',
    category: 'Genel',
  });
  const [error, setError] = useState<string>('');

  const handleChange = useCallback((
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Oturum açmanız gerekiyor.');
      }

      const decodedToken = jwtDecode<DecodedToken>(token);
      const userId = decodedToken.user.id;

      const response = await axios.post<ListData>(
        `${process.env.REACT_APP_API_URL}/api/lists`,
        { ...formData, user: userId },
        { headers: { 'x-auth-token': token } }
      );

      onCreateList(response.data);
      onClose();
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setError(err.response.data.message || 'Bir hata oluştu.');
      } else {
        setError('Liste oluşturulurken bir hata oluştu.');
      }
    }
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4"
    >
      <motion.div 
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 p-6 rounded-lg shadow-xl w-full max-w-md"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">Yeni Liste Oluştur</h2>
          <button onClick={onClose} className="text-white hover:text-indigo-200 transition duration-150">
            <X size={24} />
          </button>
        </div>
        <AnimatePresence>
          {error && <Alert message={error} />}
        </AnimatePresence>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FormInput 
            label="Liste Adı" 
            id="title" 
            value={formData.title} 
            onChange={handleChange} 
            required 
          />
          <FormTextArea 
            label="Açıklama (İsteğe bağlı)" 
            id="description" 
            value={formData.description} 
            onChange={handleChange} 
          />
          <FormSelect 
            label="Liste Tipi" 
            id="listType" 
            options={listTypes} 
            value={formData.listType} 
            onChange={handleChange} 
          />
          <FormSelect 
            label="Kategori" 
            id="category" 
            options={categories} 
            value={formData.category} 
            onChange={handleChange} 
          />
          <div className="flex items-center space-x-2">
            <input 
              type="checkbox" 
              id="isPrivate" 
              name="isPrivate" 
              checked={formData.isPrivate}
              onChange={handleChange}
              className="w-4 h-4 text-indigo-600 border-indigo-300 rounded focus:ring-indigo-500"
            />
            <label htmlFor="isPrivate" className="text-sm text-indigo-200">Gizli Liste</label>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            <motion.button 
              type="button" 
              onClick={onClose} 
              className="px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              İptal
            </motion.button>
            <motion.button 
              type="submit" 
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 flex items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <PlusCircle size={18} className="mr-2" />
              Liste Oluştur
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default CreateListForm;