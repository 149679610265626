import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { 
  List, Star, Calendar, Lock, Mail, Clipboard, Eye, PlusCircle, 
  Github, Twitter, Linkedin, Globe, ChevronLeft, ChevronRight 
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import ListItem from '../components/list/ListItem';
import CreateListForm from '../components/list/CreateListForm';

interface UserList {
  id: string;
  title: string;
  isPrivate: boolean;
  listType: 'todo' | 'ranking' | 'general';
  category?: string;
  itemCount: number;
}

interface UserData {
  username: string;
  email: string;
  socialLinks?: {
    github?: string;
    twitter?: string;
    linkedin?: string;
    website?: string;
  };
}

interface PaginationData {
  currentPage: number;
  totalPages: number;
  totalLists: number;
  listsPerPage: number;
}

const UserProfile: React.FC = () => {
  const { userName } = useParams<{ userName: string }>();
  const navigate = useNavigate();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [lists, setLists] = useState<UserList[]>([]);
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<PaginationData>({
    currentPage: 1,
    totalPages: 1,
    totalLists: 0,
    listsPerPage: 9
  });

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userName}`, {
        headers: { 'x-auth-token': token }
      });
      setUserData(userResponse.data);

      const currentUserResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/me`, {
        headers: { 'x-auth-token': token }
      });
      setIsOwnProfile(currentUserResponse.data.username === userName);

      await fetchLists(1);

      setLoading(false);
    } catch (err) {
      setError('Kullanıcı bilgileri yüklenirken bir hata oluştu.');
      setLoading(false);
    }
  };
  const handleCreateList = (newList: { title: string; description: string; isPrivate: boolean; listType: string; category: string }) => {
    console.log('Yeni liste oluşturuldu:', newList);
    setIsCreatingList(false);
    // TODO: API çağrısı yaparak yeni liste oluştur ve yönlendir
    // navigate(`/list/${newListId}`);
  };

  const fetchLists = async (page: number) => {
    const token = localStorage.getItem('token');
    if (!token) return;

    try {
      const listsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userName}/lists`, {
        headers: { 'x-auth-token': token },
        params: { page, limit: pagination.listsPerPage }
      });
      setLists(listsResponse.data.lists);
      setPagination(listsResponse.data.pagination);
    } catch (err) {
      setError('Listeler yüklenirken bir hata oluştu.');
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [userName]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      fetchLists(newPage);
    }
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPageCount = pagination.totalPages;
    const currentPage = pagination.currentPage;
    const maxPagesToShow = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    let endPage = Math.min(totalPageCount, startPage + maxPagesToShow - 1);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };


  const handleSelectList = (id: string) => {
    navigate(`/list/${id}`);
  };

  const SocialIcon: React.FC<{ platform: string; link: string }> = ({ platform, link }) => {
    const icons = {
      github: <Github size={20} />,
      twitter: <Twitter size={20} />,
      linkedin: <Linkedin size={20} />,
      website: <Globe size={20} />,
    };
    return (
      <motion.a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        className="text-white hover:text-indigo-200 transition-colors"
      >
        {icons[platform as keyof typeof icons]}
      </motion.a>
    );
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        className="w-16 h-16 border-t-4 border-white rounded-full"
      />
    </div>
  );
  
  if (error) return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500">
      <div className="text-white text-center bg-red-500 bg-opacity-75 p-6 rounded-lg shadow-xl max-w-md">
        <h2 className="text-2xl font-bold mb-4">Hata</h2>
        <p>{error}</p>
      </div>
    </div>
  );
  
  if (!userData) return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500">
      <div className="text-white text-center bg-indigo-500 bg-opacity-75 p-6 rounded-lg shadow-xl max-w-md">
        <h2 className="text-2xl font-bold mb-4">Kullanıcı Bulunamadı</h2>
        <p>Aradığınız kullanıcı profili mevcut değil.</p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-white shadow-2xl rounded-3xl overflow-hidden mb-12"
        >
          <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-8">
            <div className="flex flex-col sm:flex-row items-center">
              <div className="w-32 h-32 bg-white rounded-full flex items-center justify-center text-5xl font-bold text-indigo-600 mb-4 sm:mb-0 sm:mr-8 shadow-lg">
                {userData.username[0].toUpperCase()}
              </div>
              <div className="text-center sm:text-left">
                <h1 className="text-4xl font-bold text-white mb-2">{userData.username}</h1>
                <div className="flex items-center justify-center sm:justify-start text-indigo-100 mb-4">
                  <Mail size={18} className="mr-2" />
                  <span>{isOwnProfile ? userData.email : '********'}</span>
                </div>
                {userData.socialLinks && (
                  <div className="flex justify-center sm:justify-start space-x-4 mt-4">
                    {userData.socialLinks.github && <SocialIcon platform="github" link={userData.socialLinks.github} />}
                    {userData.socialLinks.linkedin && <SocialIcon platform="linkedin" link={userData.socialLinks.linkedin} />}
                    {userData.socialLinks.website && <SocialIcon platform="website" link={userData.socialLinks.website} />}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <motion.div whileHover={{ scale: 1.05 }} className="bg-indigo-50 p-6 rounded-xl shadow-md">
                <div className="flex items-center justify-between">
                  <Clipboard size={28} className="text-indigo-600" />
                  <span className="text-3xl font-bold text-indigo-600">{pagination.totalLists}</span>
                </div>
                <p className="text-indigo-800 mt-2 font-medium text-lg">Toplam Liste</p>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} className="bg-purple-50 p-6 rounded-xl shadow-md">
                <div className="flex items-center justify-between">
                  <Eye size={28} className="text-purple-600" />
                  <span className="text-3xl font-bold text-purple-600">
                    {lists.filter(list => !list.isPrivate).length}
                  </span>
                </div>
                <p className="text-purple-800 mt-2 font-medium text-lg">Herkese Açık Liste</p>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} className="bg-pink-50 p-6 rounded-xl shadow-md">
                <div className="flex items-center justify-between">
                  <Lock size={28} className="text-pink-600" />
                  <span className="text-3xl font-bold text-pink-600">
                    {lists.filter(list => list.isPrivate).length}
                  </span>
                </div>
                <p className="text-pink-800 mt-2 font-medium text-lg">Özel Liste</p>
              </motion.div>
            </div>
          </div>
        </motion.div>
        
        <div className="flex justify-between items-center mb-8">
          <h2 className="text-3xl font-bold text-white">Listeler ({pagination.totalLists})</h2>
          {isOwnProfile && (
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setIsCreatingList(true)}
              className="bg-white text-indigo-600 px-6 py-3 rounded-full flex items-center hover:bg-indigo-100 transition-colors duration-300 shadow-lg"
            >
              <PlusCircle size={20} className="mr-2" />
              Yeni Liste Oluştur
            </motion.button>
          )}
        </div>
        <AnimatePresence>
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.07 } }
            }}
          >
            {lists.map(list => (
              <motion.div
                key={list.id}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } }
                }}
              >
                <ListItem
                  id={list.id}
                  title={list.title}
                  itemCount={list.itemCount}
                  isPrivate={list.isPrivate}
                  category={list.category || 'Genel'}
                  onClick={() => handleSelectList(list.id)}
                />
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>

        {pagination.totalPages > 1 && (
          <div className="flex justify-center items-center space-x-4 mt-8">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handlePageChange(pagination.currentPage - 1)}
              disabled={pagination.currentPage === 1}
              className={`px-4 py-2 rounded-full ${
                pagination.currentPage === 1
                  ? 'bg-gray-300 bg-opacity-50 text-gray-400 cursor-not-allowed'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              } transition-colors duration-300`}
            >
              <ChevronLeft size={24} />
            </motion.button>
            <span className="text-lg font-medium text-white">
              Sayfa {pagination.currentPage} / {pagination.totalPages}
            </span>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => handlePageChange(pagination.currentPage + 1)}
              disabled={pagination.currentPage === pagination.totalPages}
              className={`px-4 py-2 rounded-full ${
                pagination.currentPage === pagination.totalPages
                  ? 'bg-gray-300 bg-opacity-50 text-gray-400 cursor-not-allowed'
                  : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
              } transition-colors duration-300`}
            >
              <ChevronRight size={24} />
            </motion.button>
          </div>
        )}
      </div>
      <AnimatePresence>
        {isCreatingList && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="bg-white rounded-lg shadow-xl max-w-md w-full"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <CreateListForm 
                onClose={() => setIsCreatingList(false)} 
                onCreateList={handleCreateList} 
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default UserProfile;