import React from 'react';
import { motion } from 'framer-motion';
import { ShoppingCart, Book, Utensils, Plane, Dumbbell, Music, Film, List } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const categoryStyles = [
  { name: 'Alışveriş', icon: ShoppingCart, gradient: 'from-emerald-400 to-teal-500', textColor: 'text-emerald-50', hoverGradient: 'from-emerald-500 to-teal-600' },
  { name: 'Kitaplar', icon: Book, gradient: 'from-blue-400 to-indigo-500', textColor: 'text-blue-50', hoverGradient: 'from-blue-500 to-indigo-600' },
  { name: 'Yemek', icon: Utensils, gradient: 'from-orange-400 to-red-500', textColor: 'text-orange-50', hoverGradient: 'from-orange-500 to-red-600' },
  { name: 'Seyahat', icon: Plane, gradient: 'from-purple-400 to-pink-500', textColor: 'text-purple-50', hoverGradient: 'from-purple-500 to-pink-600' },
  { name: 'Fitness', icon: Dumbbell, gradient: 'from-green-400 to-lime-500', textColor: 'text-green-50', hoverGradient: 'from-green-500 to-lime-600' },
  { name: 'Müzik', icon: Music, gradient: 'from-yellow-400 to-amber-500', textColor: 'text-yellow-50', hoverGradient: 'from-yellow-500 to-amber-600' },
  { name: 'Filmler', icon: Film, gradient: 'from-red-400 to-rose-500', textColor: 'text-red-50', hoverGradient: 'from-red-500 to-rose-600' },
  { name: 'Genel', icon: List, gradient: 'from-gray-400 to-slate-500', textColor: 'text-gray-50', hoverGradient: 'from-gray-500 to-slate-600' },
];

const CategoriesSection = () => {
  const navigate = useNavigate();

  const toTitleCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  return (
    <motion.section 
      className="mb-20 bg-gradient-to-br from-purple-700 to-indigo-800 rounded-3xl p-10 shadow-2xl overflow-hidden relative"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.4 }}
    >
      <div className="absolute top-0 left-0 w-full h-full bg-white opacity-5 z-0"></div>
      <h2 className="text-5xl font-bold text-white mb-10 text-center relative z-10">
        Keşfet ve Listele
      </h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-6 relative z-10">
        {categoryStyles.map((category, index) => (
          <motion.div
            key={category.name}
            className="group relative overflow-hidden rounded-2xl shadow-lg"
            whileHover={{ scale: 1.05, rotate: 2 }}
            whileTap={{ scale: 0.95 }}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.05 }}
          >
            <div className={`absolute inset-0 bg-gradient-to-br ${category.gradient} group-hover:${category.hoverGradient} transition-all duration-300 ease-in-out`}></div>
            <button
              onClick={() => navigate(`/category/${toTitleCase(category.name)}`)}
              className="relative w-full h-full flex flex-col items-center justify-center p-6 space-y-4"
            >
              <motion.div
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              >
                <category.icon size={48} className={`${category.textColor} group-hover:text-white transition-colors duration-300`} />
              </motion.div>
              <span className={`font-semibold ${category.textColor} text-xl group-hover:text-white transition-colors duration-300`}>
                {category.name}
              </span>
            </button>
          </motion.div>
        ))}
      </div>
    </motion.section>
  );
};

export default CategoriesSection;