import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/layout/AuthContext';
import Header from './components/layout/Header';
import HomePage from './pages/HomePage';
import ListDetailPage from './pages/ListDetailPage';
import UserProfile from './pages/UserProfile';
import Login from './pages/Login';
import Register from './pages/Register';
import AllLists from './pages/AllListsPage';
import ItemDetailPage from './pages/ItemDetailPage';
import CategoryPage from './pages/CategoryPage';

const AppContent: React.FC = () => {
  const { isLoggedIn, username, logout } = useAuth();



  return (
    <div className="min-h-screen bg-gray-100">
      <Header isLoggedIn={isLoggedIn} username={username} onLogout={logout} />
      <main className="pt-4" style={{paddingTop: 0}}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/list/:id" element={<ListDetailPage />} />
          <Route path="/:userName" element={<UserProfile />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/all-lists" element={<AllLists />} />
          <Route path="/item/:id" element={<ItemDetailPage />} />
          <Route path="/category/:category" element={<CategoryPage />} />
        </Routes>
      </main>
    </div>
  );
};

const App: React.FC = () => {
  const handleLogout = () => {
    window.location.href = '/login';
  };
  return (
    <AuthProvider onLogout={handleLogout}>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;