import React from 'react';
import { Facebook, Instagram, Twitter, ChevronRight } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 text-white py-12">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">Listart</h3>
            <p className="text-indigo-100">Listelerinizi organize edin, paylaşın ve keşfedin.</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Hızlı Bağlantılar</h3>
            <ul className="space-y-2">
              {['Ana Sayfa', 'Keşfet', 'Popüler Listeler', 'Yeni Liste Oluştur'].map((item) => (
                <li key={item}>
                  <a href="www.google.com" className="text-indigo-100 hover:text-white transition-colors duration-300 flex items-center">
                    <ChevronRight size={16} className="mr-2" />
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Destek</h3>
            <ul className="space-y-2">
              {['SSS', 'İletişim', 'Gizlilik Politikası', 'Kullanım Şartları'].map((item) => (
                <li key={item}>
                  <a href="www.google.com" className="text-indigo-100 hover:text-white transition-colors duration-300 flex items-center">
                    <ChevronRight size={16} className="mr-2" />
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">Bizi Takip Edin</h3>
            <div className="flex space-x-4">
              {[
                { icon: Facebook, href: '#' },
                { icon: Instagram, href: '#' },
                { icon: Twitter, href: '#' },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  className="bg-white bg-opacity-20 p-2 rounded-full hover:bg-opacity-30 transition-all duration-300"
                >
                  <social.icon size={20} className="text-white" />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="border-t border-indigo-300 border-opacity-30 pt-8 text-sm text-center text-indigo-100">
          &copy; {new Date().getFullYear()} Listart.co Tüm hakları saklıdır.
        </div>
      </div>
    </footer>
  );
};

export default Footer;