import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home, User, LogOut, PlusSquare, Menu, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import CreateListForm from '../list/CreateListForm';

interface HeaderProps {
  isLoggedIn: boolean;
  username?: string | undefined;
  onLogout: () => void;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, username, onLogout }) => {
  const location = useLocation();
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleCreateList = (newList: { title: string; description: string; isPrivate: boolean; listType: string; category: string }) => {
    console.log('Yeni liste oluşturuldu:', newList);
    setIsCreatingList(false);
    // TODO: API çağrısı yaparak yeni liste oluştur ve yönlendir
    // navigate(`/list/${newListId}`);
  };

  const navItems = [
    { name: 'Ana Sayfa', icon: <Home size={20} />, path: '/' },
    ...(isLoggedIn
      ? [
          { name: 'Yeni Liste', icon: <PlusSquare size={20} />, action: () => setIsCreatingList(true) },
          { name: username, icon: <User size={20} />, path: `/${username}` },
          { name: 'Çıkış', icon: <LogOut size={20} />, action: onLogout },
        ]
      : [{ name: 'Giriş Yap', icon: <User size={20} />, path: '/login' }]),
  ];

  return (
    <>
      <header className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500 text-white shadow-lg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <Link to="/" className="flex items-center space-x-2">
              <motion.span 
                className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-pink-200"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                listart
              </motion.span>
            </Link>
            
            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-1">
              {navItems.map((item, index) => (
                item.path ? (
                  <motion.div
                    key={index}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Link
                      to={item.path}
                      className={`flex items-center space-x-1 px-4 py-2 rounded-full text-sm font-medium ${
                        location.pathname === item.path
                          ? 'bg-white bg-opacity-20 text-white shadow-inner'
                          : 'text-white hover:bg-white hover:bg-opacity-10 hover:text-white'
                      } transition-all duration-200`}
                    >
                      {item.icon}
                      <span>{item.name}</span>
                    </Link>
                  </motion.div>
                ) : (
                  <motion.button
                    key={index}
                    onClick={item.action}
                    className="flex items-center space-x-1 px-4 py-2 rounded-full text-sm font-medium text-white hover:bg-white hover:bg-opacity-10 transition-all duration-200"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {item.icon}
                    <span>{item.name}</span>
                  </motion.button>
                )
              ))}
            </nav>

            {/* Mobile menu button */}
            <motion.div 
              className="md:hidden"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-full text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white transition-all duration-200"
              >
                {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </motion.div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMobileMenuOpen && (
            <motion.div 
              className="md:hidden"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-gradient-to-b from-purple-600 to-pink-500">
                {navItems.map((item, index) => (
                  item.path ? (
                    <motion.div
                      key={index}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <Link
                        to={item.path}
                        className={`flex items-center space-x-2 ${
                          location.pathname === item.path
                            ? 'bg-white bg-opacity-20 text-white shadow-inner'
                            : 'text-white hover:bg-white hover:bg-opacity-10 hover:text-white'
                        } block px-3 py-2 rounded-md text-base font-medium transition-all duration-200`}
                        onClick={() => setIsMobileMenuOpen(false)}
                      >
                        {item.icon}
                        <span>{item.name}</span>
                      </Link>
                    </motion.div>
                  ) : (
                    <motion.button
                      key={index}
                      onClick={() => {
                        item.action?.();
                        setIsMobileMenuOpen(false);
                      }}
                      className="flex items-center space-x-2 text-white hover:bg-white hover:bg-opacity-10 block px-3 py-2 rounded-md text-base font-medium w-full text-left transition-all duration-200"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      {item.icon}
                      <span>{item.name}</span>
                    </motion.button>
                  )
                ))}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </header>

      <AnimatePresence>
        {isCreatingList && (
          <motion.div 
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div 
              className="bg-white rounded-lg shadow-xl max-w-md w-full"
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
            >
              <CreateListForm 
                onClose={() => setIsCreatingList(false)} 
                onCreateList={handleCreateList} 
              />
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Header;