import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Check, Edit2, Trash2, Star, ChevronRight, GripVertical } from 'lucide-react';
import { ListItem, ListType } from '../types';
import { useNavigate } from 'react-router-dom';

interface SortableItemProps {
  item: ListItem;
  index: number;
  listType: ListType;
  onToggleComplete: (id: string, e: React.MouseEvent) => void;
  onDeleteItem: (id: string, e: React.MouseEvent) => void;
  isOwner: boolean;
}

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  index,
  listType,
  onToggleComplete,
  onDeleteItem,
  isOwner
}) => {
  const navigate = useNavigate();

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleItemClick = () => {
    navigate(`/item/${item.id}`);
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all duration-300 cursor-pointer"
      onClick={handleItemClick}
    >
      <div className="flex items-center p-4">
        {isOwner && (
          <div {...listeners} className="mr-3" onClick={(e) => e.stopPropagation()}>
            <GripVertical size={20} className="text-gray-400" />
          </div>
        )}
        {listType === 'todo' && (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onToggleComplete(item.id, e);
            }}
            className={`mr-4 rounded-full border-2 p-1 ${
              item.completed
                ? 'border-green-500 bg-green-500 text-white'
                : 'border-gray-300 text-gray-300 hover:border-indigo-500 hover:text-indigo-500'
            } transition-colors duration-300`}
          >
            <Check size={16} />
          </button>
        )}
        {listType === 'ranking' && (
          <div className="mr-4 w-8 h-8 bg-indigo-600 text-white rounded-full flex items-center justify-center font-bold">
            {index + 1}
          </div>
        )}
        <div className="flex-grow">
          <span
            className={`text-lg ${
              listType === 'todo' && item.completed ? 'text-gray-500 line-through' : 'text-gray-800'
            }`}
          >
            {item.name}
          </span>
          {item.description && (
            <p className="text-sm text-gray-500 mt-1">{item.description}</p>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {listType === 'ranking' && (
            <button className="text-yellow-500 hover:text-yellow-600 transition-colors p-1" onClick={(e) => e.stopPropagation()}>
              <Star size={18} />
            </button>
          )}
          <ChevronRight size={20} className="text-gray-400" />
        </div>
      </div>
    </li>
  );
};

export default SortableItem;