import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion } from 'framer-motion';
import { ArrowLeft, Edit2, Trash2, Check, X } from 'lucide-react';

interface ListItem {
  id: string;
  name: string;
  description: string;
  completed: boolean;
  createdAt: string;
  updatedAt: string;
}

const ItemDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [item, setItem] = useState<ListItem | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [editedDescription, setEditedDescription] = useState('');

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await axios.get<ListItem>(`${process.env.REACT_APP_API_URL}/api/items/${id}`);
        setItem(response.data);
        setEditedName(response.data.name);
        setEditedDescription(response.data.description || '');
      } catch (error) {
        console.error('Error fetching item:', error);
      }
    };

    fetchItem();
  }, [id]);

  const handleEdit = async () => {
    if (!isEditing) {
      setIsEditing(true);
    } else {
      try {
        const response = await axios.put<ListItem>(`${process.env.REACT_APP_API_URL}/api/items/${id}`, {
          name: editedName,
          description: editedDescription
        });
        setItem(response.data);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating item:', error);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/items/${id}`);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  if (!item) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 py-12 px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-2xl mx-auto bg-white rounded-xl shadow-2xl overflow-hidden"
      >
        <div className="p-8">
          <div className="flex justify-between items-center mb-6">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate(-1)}
              className="flex items-center text-indigo-600 hover:text-indigo-800 transition-colors"
            >
              <ArrowLeft size={24} className="mr-2" />
              Geri Dön
            </motion.button>
            <div className="flex space-x-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleEdit}
                className="p-2 bg-indigo-100 text-indigo-600 rounded-full hover:bg-indigo-200 transition-colors"
              >
                {isEditing ? <Check size={20} /> : <Edit2 size={20} />}
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleDelete}
                className="p-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 transition-colors"
              >
                <Trash2 size={20} />
              </motion.button>
            </div>
          </div>
          {isEditing ? (
            <div className="space-y-4">
              <input
                type="text"
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              />
              <textarea
                value={editedDescription}
                onChange={(e) => setEditedDescription(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                rows={4}
                placeholder="Açıklama"
              />
            </div>
          ) : (
            <div>
              <h1 className="text-3xl font-bold text-gray-800 mb-4">{item.name}</h1>
              <p className="text-gray-600 mb-4">{item.description}</p>
              <div className="mt-4 text-sm text-gray-500">
                <p>Oluşturulma Tarihi: {new Date(item.createdAt).toLocaleString()}</p>
                <p>Son Güncelleme: {new Date(item.updatedAt).toLocaleString()}</p>
              </div>
            </div>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default ItemDetailPage;