import React from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Share2, Lock, MoreHorizontal, ListIcon, Star, Calendar } from 'lucide-react';
import { ListType } from '../types';
import { useAuth } from '../layout/AuthContext';

interface ListHeaderProps {
  title: string;
  itemCount: number;
  listType: ListType;
  onBack: () => void;
}

const ListHeader: React.FC<ListHeaderProps> = ({ title, itemCount, listType, onBack }) => {
  const { isLoggedIn } = useAuth();
  const getListIcon = () => {
    switch (listType) {
      case 'todo':
        return <ListIcon size={32} className="text-white" />;
      case 'ranking':
        return <Star size={32} className="text-white" />;
      default:
        return <Calendar size={32} className="text-white" />;
    }
  };

  return (
    <header className="bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-600 text-white p-8">
      {isLoggedIn && (
        <div className="flex justify-between items-center mb-6">
        <motion.button 
          className="flex items-center text-white hover:text-indigo-200 transition-colors"
          onClick={onBack}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <ArrowLeft size={24} className="mr-2" />
          Geri
        </motion.button>
        <div className="flex space-x-4">
          {['share', 'lock', 'more'].map((action, index) => (
            <motion.button
              key={action}
              className="p-2 rounded-full bg-white bg-opacity-20 hover:bg-opacity-30 transition-colors"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
            >
              {action === 'share' && <Share2 size={20} />}
              {action === 'lock' && <Lock size={20} />}
              {action === 'more' && <MoreHorizontal size={20} />}
            </motion.button>
          ))}
        </div>
      </div>
      )}
      <div className="flex items-center">
        <motion.div 
          className="bg-white bg-opacity-20 p-4 rounded-full mr-6"
          initial={{ rotate: -180, opacity: 0 }}
          animate={{ rotate: 0, opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {getListIcon()}
        </motion.div>
        <div>
          <motion.h1 
            className="text-4xl font-bold mb-2"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {title}
          </motion.h1>
          <motion.p 
            className="text-indigo-100"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            {itemCount} öğe • Son güncelleme: {new Date().toLocaleString()}
          </motion.p>
        </div>
      </div>
    </header>
  );
};

export default ListHeader;