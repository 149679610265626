import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SortableItem from './SortableItem';
import { ListItem, ListType } from '../types';

interface ItemListProps {
  items: ListItem[];
  listType: ListType;
  isOwner: boolean;
  onToggleComplete: (id: string) => void;
  onDeleteItem: (id: string) => void;
  onItemClick: (id: string) => void;
  onOpenLink: (url: string) => void;
}

const ItemList: React.FC<ItemListProps> = ({
  items,
  listType,
  isOwner,
  onToggleComplete,
  onDeleteItem,
  onItemClick,
  onOpenLink
}) => {
  return (
    <motion.ul 
      className="space-y-4 mb-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.5 }}
    >
      <AnimatePresence>
        {items.map((item, index) => (
          <motion.li
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3, delay: index * 0.05 }}
          >
            <SortableItem
              item={item}
              index={index}
              listType={listType}
              onToggleComplete={onToggleComplete}
              onDeleteItem={onDeleteItem}
              isOwner={isOwner}
            />
          </motion.li>
        ))}
      </AnimatePresence>
      {items.length === 0 && (
        <motion.p 
          className="text-center text-gray-500 py-8 text-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Eşleşen öğe bulunamadı.
        </motion.p>
      )}
    </motion.ul>
  );
};

export default ItemList;