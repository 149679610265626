import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, ArrowLeft, Layers, Search, Filter, Grid, List as ListIcon } from 'lucide-react';
import ListItem from '../components/list/ListItem';

interface List {
  id: string;
  title: string;
  itemCount: number;
  isPrivate: boolean;
  category: string;
}

const CategoryPage: React.FC = () => {
  const { category } = useParams<{ category: string }>();
  const navigate = useNavigate();
  const [lists, setLists] = useState<List[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalLists, setTotalLists] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
  const [sortBy, setSortBy] = useState<'newest' | 'popular'>('newest');

  const pageTopRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchLists(currentPage);
    window.scrollTo(0, 0);
  }, [category, currentPage, sortBy]);

  const fetchLists = async (page: number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/lists/public`, {
        params: {
          category: category,
          page: page,
          limit: 9,
          sortBy: sortBy
        }
      });
      setLists(response.data.lists);
      setTotalPages(response.data.totalPages);
      setTotalLists(response.data.totalLists);
    } catch (err) {
      setError('Listeler yüklenirken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    pageTopRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getCategoryColor = () => {
    const colors: { [key: string]: string } = {
      'Alışveriş': 'from-emerald-400 to-teal-500',
      'Kitaplar': 'from-blue-400 to-indigo-500',
      'Yemek': 'from-orange-400 to-red-500',
      'Seyahat': 'from-purple-400 to-pink-500',
      'Fitness': 'from-green-400 to-lime-500',
      'Müzik': 'from-yellow-400 to-amber-500',
      'Filmler': 'from-red-400 to-rose-500',
      'Genel': 'from-gray-400 to-slate-500'
    };
    return colors[category || 'Genel'] || 'from-indigo-400 to-purple-500';
  };

  const toTitleCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const filteredLists = lists.filter(list => 
    list.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderPagination = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="flex justify-center items-center space-x-2 mt-8">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className={`px-3 py-2 rounded-full ${
            currentPage === 1
              ? 'bg-gray-300 bg-opacity-50 text-gray-400 cursor-not-allowed'
              : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
          } transition-colors duration-300`}
        >
          <ChevronLeft size={20} />
        </motion.button>
        {pageNumbers.map((number) => (
          <motion.button
            key={number}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handlePageChange(number)}
            className={`px-4 py-2 rounded-full ${
              currentPage === number
                ? 'bg-white text-indigo-600'
                : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
            } transition-colors duration-300`}
          >
            {number}
          </motion.button>
        ))}
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className={`px-3 py-2 rounded-full ${
            currentPage === totalPages
              ? 'bg-gray-300 bg-opacity-50 text-gray-400 cursor-not-allowed'
              : 'bg-white bg-opacity-20 text-white hover:bg-opacity-30'
          } transition-colors duration-300`}
        >
          <ChevronRight size={20} />
        </motion.button>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 py-12 px-4 sm:px-6 lg:px-8">
      <div ref={pageTopRef} className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className={`bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl p-8 mb-12 shadow-2xl relative overflow-hidden bg-gradient-to-br ${getCategoryColor()}`}
        >
          <div className="relative z-10">
            <div className="flex items-center justify-between mb-6">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate(-1)}
                className="flex items-center text-white hover:text-indigo-200 transition-colors"
              >
                <ArrowLeft size={20} className="mr-2" />
                <span className="text-sm font-medium">Geri Dön</span>
              </motion.button>
              <div className="flex items-center bg-white bg-opacity-20 rounded-full py-1 px-3">
                <Layers size={16} className="text-white mr-2" />
                <span className="text-white text-sm font-medium">{totalLists} Liste</span>
              </div>
            </div>
            <h1 className="text-5xl font-extrabold text-white mb-4 tracking-tight">
              {toTitleCase(category || '')} <span className="text-pink-300">Listeleri</span>
            </h1>
            <p className="text-xl text-white text-opacity-90 max-w-2xl">
              {toTitleCase(category || '')} kategorisindeki en popüler ve güncel listeler. Keşfedin, ilham alın ve kendi listelerinizi oluşturun.
            </p>
          </div>
        </motion.div>

        <div className="mb-8 flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0">
          <div className="relative w-full sm:w-64">
            <input
              type="text"
              placeholder="Liste ara..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-full bg-white bg-opacity-20 text-white placeholder-white placeholder-opacity-70 focus:outline-none focus:ring-2 focus:ring-white"
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-white opacity-70" size={18} />
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value as 'newest' | 'popular')}
              className="bg-white bg-opacity-20 text-white rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-white"
            >
              <option value="newest">En Yeni</option>
              <option value="popular">En Popüler</option>
            </select>
            <div className="flex bg-white bg-opacity-20 rounded-full p-1">
              <button
                onClick={() => setViewMode('grid')}
                className={`p-2 rounded-full ${viewMode === 'grid' ? 'bg-white bg-opacity-30' : ''}`}
              >
                <Grid size={18} className="text-white" />
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`p-2 rounded-full ${viewMode === 'list' ? 'bg-white bg-opacity-30' : ''}`}
              >
                <ListIcon size={18} className="text-white" />
              </button>
            </div>
          </div>
        </div>

        {loading && (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
          </div>
        )}

        {error && (
          <div className="text-center py-10 text-red-200 bg-red-500 bg-opacity-50 rounded-xl backdrop-filter backdrop-blur-lg">
            {error}
          </div>
        )}

        <AnimatePresence>
          <motion.div
            className={`grid ${viewMode === 'grid' ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3' : 'grid-cols-1'} gap-6 mb-8`}
            initial="hidden"
            animate="visible"
            variants={{
              visible: { transition: { staggerChildren: 0.05 } }
            }}
          >
            {filteredLists.map((list) => (
              <motion.div
                key={list.id}
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0, transition: { duration: 0.3 } }
                }}
              >
                <ListItem
                  id={list.id}
                  title={list.title}
                  itemCount={list.itemCount}
                  isPrivate={list.isPrivate}
                  category={list.category}
                  onClick={() => navigate(`/list/${list.id}`)}
                />
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>

        {!loading && filteredLists.length === 0 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-center py-10 text-white bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl shadow-xl"
          >
            Bu kategoride eşleşen liste bulunmamaktadır.
          </motion.div>
        )}

        {totalPages > 1 && renderPagination()}
      </div>
    </div>
  );
};

export default CategoryPage;