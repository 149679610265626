import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { List, Star, Calendar, ChevronLeft, ChevronRight } from 'lucide-react';

interface ListItem {
  _id: string;
  title: string;
  itemCount: number;
  isPrivate: boolean;
  listType: 'todo' | 'ranking' | 'general';
}

interface ListItemProps {
  list: ListItem;
  onClick: () => void;
}

const ListItemComponent: React.FC<ListItemProps> = ({ list, onClick }) => (
  <div 
    className="bg-white p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow"
    onClick={onClick}
  >
    <div className="flex items-center justify-between mb-4">
      <div className="p-2 bg-indigo-100 rounded-full">
        {getIcon(list.listType)}
      </div>
      {list.isPrivate && (
        <span className="text-xs font-medium bg-gray-200 px-2 py-1 rounded-full">Özel</span>
      )}
    </div>
    <h3 className="font-semibold text-lg mb-2 text-gray-800">{list.title}</h3>
    <p className="text-gray-600">{list.itemCount} öğe</p>
  </div>
);

const getIcon = (listType: string) => {
  switch (listType) {
    case 'todo':
      return <List size={24} className="text-indigo-600" />;
    case 'ranking':
      return <Star size={24} className="text-indigo-600" />;
    default:
      return <Calendar size={24} className="text-indigo-600" />;
  }
};

const AllLists: React.FC = () => {
  const navigate = useNavigate();
  const [lists, setLists] = useState<ListItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchLists(currentPage);
  }, [currentPage]);

  const fetchLists = async (page: number) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/lists/public?page=${page}&limit=10`);
      setLists(response.data.lists);
      setTotalPages(response.data.totalPages);
    } catch (err) {
      setError('Listeler yüklenirken bir hata oluştu.');
    }
    setIsLoading(false);
  };

  const handleSelectList = (id: string) => {
    navigate(`/list/${id}`);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <main className="max-w-6xl mx-auto p-6">
        <h2 className="text-3xl font-semibold text-gray-800 mb-8">Tüm Listeler</h2>
        
        {isLoading && <p className="text-center">Yükleniyor...</p>}
        {error && <p className="text-center text-red-500">{error}</p>}
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
          {lists.map((list) => (
            <ListItemComponent 
              key={list._id}
              list={list}
              onClick={() => handleSelectList(list._id)}
            />
          ))}
        </div>

        <div className="flex justify-center items-center space-x-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md disabled:bg-gray-400"
          >
            <ChevronLeft size={20} />
          </button>
          <span>{currentPage} / {totalPages}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md disabled:bg-gray-400"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </main>
    </div>
  );
};

export default AllLists;