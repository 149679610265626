import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import ListHeader from './ListHeader';
import SearchBar from './SearchBar';
import ItemList from './ItemList';
import AddItemPopup from './AddItemPopup';

import { ListItem, ListType } from '../types';

const ListDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string | null>(null);
  const [listType, setListType] = useState<ListType>('general');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [items, setItems] = useState<ListItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [listOwner, setListOwner] = useState<number | null>(null);
  const [isAddingItem, setIsAddingItem] = useState(false);

  const token = localStorage.getItem('token');
  const userId = token ? JSON.parse(atob(token.split('.')[1])).user.id : null;
  const isOwner = userId === listOwner;

  useEffect(() => {
    const fetchListDetails = async () => {
      try {
        if (!id) throw new Error('List ID is undefined');

        const listResponse = await axios.get<{ title: string; listType: ListType; user: string, UserId: number }>(`${process.env.REACT_APP_API_URL}/api/lists/${id}`, {
          headers: { 'x-auth-token': token }
        });

        setTitle(listResponse.data.title);
        setListType(listResponse.data.listType);
        setListOwner(listResponse.data.UserId);

        const itemsResponse = await axios.get<ListItem[]>(`${process.env.REACT_APP_API_URL}/api/items/list/${id}`);
        const sortedItems = itemsResponse.data.sort((a, b) => a.order - b.order);
        setItems(sortedItems);
        setLoading(false);
      } catch (err) {
        setError('Veriler yüklenirken bir hata oluştu.');
        setLoading(false);
      }
    };

    fetchListDetails();
  }, [id, token]);

  const filteredItems = useMemo(() => {
    return items.filter(item => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [items, searchTerm]);

  const handleToggleComplete = async (itemId: string) => {
    if (listType === 'todo' && token) {
      try {
        const itemToUpdate = items.find(item => item.id === itemId);
        if (itemToUpdate) {
          const response = await axios.put<ListItem>(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`, {
            ...itemToUpdate,
            completed: !itemToUpdate.completed
          }, {
            headers: { 'x-auth-token': token }
          });
          setItems(items.map(item => item.id === itemId ? response.data : item));
        }
      } catch (err) {
        setError('Öğe güncellenirken bir hata oluştu.');
      }
    }
  };

  const handleDeleteItem = async (itemId: string) => {
    if (isOwner && token) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/items/${itemId}`, {
          headers: { 'x-auth-token': token }
        });
        setItems(items.filter(item => item.id !== itemId));
      } catch (err) {
        setError('Öğe silinirken bir hata oluştu.');
      }
    }
  };

  const handleAddItem = () => {
    if (isOwner) {
      setIsAddingItem(true);
    }
  };

  const handleAddItemSubmit = async (name: string, description: string, link: string) => {
    if (isOwner && token) {
      try {
        const newOrder = items.length > 0 ? Math.max(...items.map(item => item.order)) + 1 : 0;
        const response = await axios.post<ListItem>(`${process.env.REACT_APP_API_URL}/api/items`, {
          listId: id,
          name: name,
          completed: false,
          description: description,
          order: newOrder,
          link: link
        }, {
          headers: { 'x-auth-token': token }
        });
        setItems([...items, response.data]);
      } catch (err) {
        setError('Yeni öğe eklenirken bir hata oluştu.');
      }
    }
  };

  const handleItemClick = (itemId: string) => {
    navigate(`/item/${itemId}`);
  };

  const handleOpenLink = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const renderItemList = () => {
      return (
        <ItemList
          items={filteredItems}
          listType={listType}
          isOwner={isOwner}
          onToggleComplete={handleToggleComplete}
          onDeleteItem={handleDeleteItem}
          onItemClick={handleItemClick}
          onOpenLink={handleOpenLink}
        />
      );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }
  if (error) return <div className="text-center py-10 text-red-500">{error}</div>;
  if (!title) return null;

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 flex flex-col">
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="flex-grow flex flex-col"
      >
        <motion.div 
          className="bg-white shadow-2xl flex-grow flex flex-col"
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <ListHeader
            title={title}
            itemCount={items.length}
            listType={listType}
            onBack={() => navigate(-1)}
          />

          <div className="p-6 flex-grow flex flex-col">
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />

            <div className="mt-6 flex-grow overflow-y-auto">
              {renderItemList()}
            </div>

            {isOwner && (
              <motion.button 
                onClick={handleAddItem}
                className="mt-6 w-full py-4 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg flex items-center justify-center hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 shadow-lg text-lg font-semibold"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.6 }}
              >
                Yeni Öğe Ekle
              </motion.button>
            )}
          </div>
        </motion.div>
      </motion.div>

      <AnimatePresence>
        {isAddingItem && (
          <AddItemPopup
            isOpen={isAddingItem}
            onClose={() => setIsAddingItem(false)}
            onAddItem={handleAddItemSubmit}
            listType={listType}
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default ListDetailPage;