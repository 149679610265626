import React, { useState, ChangeEvent, FormEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, PlusCircle, Link as LinkIcon } from 'lucide-react';

interface AddItemPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onAddItem: (name: string, description: string, link: string) => void;
  listType: string;
}

interface FormInputProps {
  label: string;
  id: string;
  type?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  placeholder?: string;
}

interface FormTextAreaProps {
  label: string;
  id: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
}

const FormInput: React.FC<FormInputProps> = ({ label, id, type = 'text', value, onChange, required = false, placeholder }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-200 mb-1">{label}</label>
    <input 
      type={type} 
      id={id} 
      name={id} 
      required={required}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full px-3 py-2 bg-white bg-opacity-20 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent placeholder-indigo-300 text-white"
    />
  </div>
);

const FormTextArea: React.FC<FormTextAreaProps> = ({ label, id, value, onChange, placeholder }) => (
  <div className="mb-4">
    <label htmlFor={id} className="block text-sm font-medium text-indigo-200 mb-1">{label}</label>
    <textarea 
      id={id} 
      name={id} 
      rows={3}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="w-full px-3 py-2 bg-white bg-opacity-20 border border-indigo-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent placeholder-indigo-300 text-white"
    ></textarea>
  </div>
);

const AddItemPopup: React.FC<AddItemPopupProps> = ({ isOpen, onClose, onAddItem, listType }) => {
  const [itemName, setItemName] = useState('');
  const [itemDescription, setItemDescription] = useState('');
  const [itemUrl, setItemUrl] = useState('');

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (itemName.trim()) {
      onAddItem(itemName, itemDescription, listType === 'link' ? itemUrl : '');
      setItemName('');
      setItemDescription('');
      setItemUrl('');
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500 p-6 rounded-lg shadow-xl w-full max-w-md"
      >
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-white">
            {listType === 'links' ? 'Yeni Link Ekle' : 'Yeni Öğe Ekle'}
          </h2>
          <button onClick={onClose} className="text-white hover:text-indigo-200 transition duration-150">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <FormInput
            label={listType === 'links' ? 'Link Başlığı' : 'Öğe Adı'}
            id="itemName"
            value={itemName}
            onChange={(e) => setItemName(e.target.value)}
            required
            placeholder={listType === 'links' ? 'Örn: Google Ana Sayfası' : 'Öğe adını girin'}
          />
          {listType === 'link' && (
            <FormInput
              label="URL"
              id="itemUrl"
              type="url"
              value={itemUrl}
              onChange={(e) => setItemUrl(e.target.value)}
              required
              placeholder="https://www.ornek.com"
            />
          )}
          <FormTextArea
            label="Açıklama (İsteğe bağlı)"
            id="itemDescription"
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            placeholder={listType === 'link' ? 'Link hakkında kısa bir açıklama' : 'Öğe hakkında açıklama'}
          />
          <div className="flex justify-end space-x-2 mt-6">
            <motion.button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              İptal
            </motion.button>
            <motion.button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 flex items-center"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {listType === 'link' ? <LinkIcon size={18} className="mr-2" /> : <PlusCircle size={18} className="mr-2" />}
              {listType === 'link' ? 'Link Ekle' : 'Öğe Ekle'}
            </motion.button>
          </div>
        </form>
      </motion.div>
    </motion.div>
  );
};

export default AddItemPopup;