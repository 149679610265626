import React from 'react';
import ListDetail from '../components/list/ListDetail';

const ListDetailPage: React.FC = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 via-purple-100 to-pink-100">
      <ListDetail/>
    </div>
  );
};

export default ListDetailPage;