import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';

interface AuthContextType {
  isLoggedIn: boolean;
  username: string;
  login: (username: string, token: string) => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType>({
  isLoggedIn: false,
  username: '',
  login: () => {},
  logout: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
  onLogout: () => void;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children, onLogout }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState('');

  const checkTokenExpiration = useCallback((token: string): boolean => {
    try {
      const payload = JSON.parse(atob(token.split('.')[1]));
      const expirationTime = payload.exp * 1000; // Convert to milliseconds
      return Date.now() >= expirationTime;
    } catch (error) {
      console.error('Invalid token:', error);
      return true; // Assume token is expired if it can't be parsed
    }
  }, []);

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    setIsLoggedIn(false);
    setUsername('');
    onLogout();
  }, [onLogout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUsername = localStorage.getItem('username');
    if (token && storedUsername) {
      if (checkTokenExpiration(token)) {
        handleLogout();
      } else {
        setIsLoggedIn(true);
        setUsername(storedUsername);
      }
    }
  }, [checkTokenExpiration, handleLogout]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token && isLoggedIn && checkTokenExpiration(token)) {
      handleLogout();
    }
  });

  const login = useCallback((username: string, token: string) => {
    localStorage.setItem('token', token);
    localStorage.setItem('username', username);
    setIsLoggedIn(true);
    setUsername(username);
  }, []);

  const contextValue = {
    isLoggedIn,
    username,
    login,
    logout: handleLogout
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);