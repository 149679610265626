import React from 'react';
import { motion } from 'framer-motion';
import { Calendar, Lock, Eye, Star, ShoppingCart, Book, Utensils, Plane, Dumbbell, Music, Film, List } from 'lucide-react';

interface ListItemProps {
  title: string;
  itemCount: number;
  isPrivate: boolean;
  category: string;
  onClick: () => void;
  id: string;
}

const ListItem: React.FC<ListItemProps> = ({ title, itemCount, isPrivate, category, onClick, id }) => {
  const getGradient = () => {
    const gradients = [
      'from-indigo-400 to-blue-500',
      'from-purple-400 to-pink-500',
      'from-green-400 to-teal-500',
      'from-yellow-400 to-orange-500',
      'from-red-400 to-pink-500',
      'from-blue-400 to-indigo-500',
      'from-teal-400 to-cyan-500',
      'from-orange-400 to-red-500',
      'from-pink-400 to-rose-500',
      'from-lime-400 to-green-500',
      'from-amber-400 to-yellow-500',
      'from-emerald-400 to-green-500',
      'from-sky-400 to-blue-500',
      'from-violet-400 to-purple-500',
      'from-fuchsia-400 to-pink-500',
      'from-rose-400 to-red-500',
      'from-cyan-400 to-blue-500',
      'from-blue-400 to-violet-500',
      'from-indigo-400 to-purple-500',
      'from-emerald-400 to-teal-500'
    ];
    const idString = String(id);
    const hash = idString.split('').reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    
    return gradients[Math.abs(hash) % gradients.length];
  };

  const getIcon = () => {
    const icons = {
      'Alışveriş': ShoppingCart,
      'Kitaplar': Book,
      'Yemek': Utensils,
      'Seyahat': Plane,
      'Fitness': Dumbbell,
      'Müzik': Music,
      'Filmler': Film,
      'Genel': List,
    };
    const Icon = icons[category as keyof typeof icons] || List;
    return <Icon size={24} className="text-white" />;
  };

  return (
    <motion.div 
      className={`bg-gradient-to-br ${getGradient()} rounded-xl shadow-lg cursor-pointer overflow-hidden`}
      onClick={onClick}
      whileHover={{ scale: 1.03, y: -5 }}
      whileTap={{ scale: 0.98 }}
    >
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <div className="bg-white bg-opacity-30 p-2 rounded-full">
            {getIcon()}
          </div>
          {isPrivate ? (
            <div className="bg-red-500 text-white p-1 rounded-full">
              <Lock size={16} />
            </div>
          ) : (
            <div className="bg-green-500 text-white p-1 rounded-full">
              <Eye size={16} />
            </div>
          )}
        </div>
        <h3 className="font-bold text-xl text-white mb-2 truncate">{title}</h3>
        <div className="flex justify-between items-center text-white text-opacity-90">
          <span className="flex items-center text-sm">
            <Calendar size={16} className="mr-1" />
            {itemCount} öğe
          </span>
          <span className="text-sm font-medium bg-white bg-opacity-30 px-2 py-1 rounded-full">
            {category}
          </span>
        </div>
      </div>
      <div className="bg-white bg-opacity-20 p-3">
        <div className="flex justify-between items-center">
          <span className="text-white text-opacity-90 text-sm">Son güncelleme: Bugün</span>
          <Star size={16} className="text-yellow-300" />
        </div>
      </div>
    </motion.div>
  );
};

export default ListItem;