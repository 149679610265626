import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { List, Star, Book, ShoppingCart, Utensils, Plane, Dumbbell, Music, Film, ArrowRight, Search, TrendingUp, Users, Plus,ChevronDown} from 'lucide-react';
import { useAuth } from '../components/layout/AuthContext';
import { motion } from 'framer-motion';
import CategoriesSection from '../components/item/CategoriesSection';
import Footer from '../components/layout/Footer';
import ListItem from '../components/list/ListItem';

interface ListData {
  id: string;
  title: string;
  isPrivate: boolean;
  itemCount: number;
  category: string;
  coverImage: string;
}

const categories = [
  { name: 'Alışveriş', icon: <ShoppingCart size={24} className="text-indigo-600" /> },
  { name: 'Kitaplar', icon: <Book size={24} className="text-indigo-600" /> },
  { name: 'Yemek', icon: <Utensils size={24} className="text-indigo-600" /> },
  { name: 'Seyahat', icon: <Plane size={24} className="text-indigo-600" /> },
  { name: 'Fitness', icon: <Dumbbell size={24} className="text-indigo-600" /> },
  { name: 'Müzik', icon: <Music size={24} className="text-indigo-600" /> },
  { name: 'Filmler', icon: <Film size={24} className="text-indigo-600" /> },
  { name: 'Genel', icon: <List size={24} className="text-indigo-600" /> },
];

const HomePage: React.FC = () => {
  const [categoryLists, setCategoryLists] = useState<{ [key: string]: ListData[] }>({});
  const [featuredLists, setFeaturedLists] = useState<ListData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError('');
      try {
        const categoryPromises = categories.map(category => 
          axios.get(`${process.env.REACT_APP_API_URL}/api/lists/public?category=${category.name}&page=1&limit=3`)
        );
        const responses = await Promise.all(categoryPromises);
        
        const newCategoryLists: { [key: string]: ListData[] } = {};
        responses.forEach((response, index) => {
          newCategoryLists[categories[index].name] = response.data.lists;
        });
        setCategoryLists(newCategoryLists);

        const allLists = Object.values(newCategoryLists).flat();
        const featured = allLists
          .sort(() => 0.5 - Math.random())
          .slice(0, 3);
        setFeaturedLists(featured);

      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Verileri yüklerken bir hata oluştu.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSelectList = (id: string) => {
    navigate(`/list/${id}`);
  };

  const handleStartNow = () => {
    navigate('/login');
  };

  const getIcon = (category: string) => {
    const foundCategory = categories.find(c => c.name.toLowerCase() === category.toLowerCase());
    return foundCategory ? foundCategory.icon : <List size={24} className="text-indigo-600" />;
  };

  const getPlaceholderImage = (index: number) => {
    return `/api/placeholder/${400 + index}/${300 + index}`;
  };

  const toTitleCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  if (loading) return (
    <div className="flex justify-center items-center h-screen bg-gradient-to-br from-indigo-600 via-purple-600 to-pink-500">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
        className="w-16 h-16 border-t-4 border-white rounded-full"
      />
    </div>
  );

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500 flex items-center justify-center">
        <div className="bg-white p-8 rounded-xl shadow-2xl">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Hata</h2>
          <p className="text-gray-700">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-500 via-purple-500 to-pink-500">
      <main className="max-w-7xl mx-auto p-6">
        {/* Hero Section */}
        {!isLoggedIn && (
        <motion.section 
          className="text-center py-20 px-4 sm:px-6 md:px-8 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl shadow-2xl mb-16 relative overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-indigo-600 to-purple-600 opacity-50 z-0"></div>
          <div className="relative z-10 max-w-4xl mx-auto">
            <motion.h1 
              className="text-5xl sm:text-6xl md:text-7xl font-extrabold text-white mb-6 leading-tight"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2, duration: 0.5 }}
            >
              Listelerinizi <span className="text-pink-300">Keşfedin</span> ve <span className="text-yellow-300">Paylaşın</span>
            </motion.h1>
            <motion.p 
              className="text-xl sm:text-2xl text-gray-100 mb-8 max-w-3xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
            >
              Hayatınızı düzenleyin, fikirlerinizi paylaşın ve toplulukla etkileşime geçin. Listart ile liste oluşturmanın yeni yolunu keşfedin.
            </motion.p>
            <motion.button 
              className="bg-white text-indigo-600 px-8 py-4 rounded-full font-semibold text-lg hover:bg-indigo-100 transition-all duration-300 transform hover:scale-105 shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleStartNow}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
            >
              Hemen Başlayın
            </motion.button>
          </div>
          <motion.div 
      className="absolute bottom-4 left-0 right-0 flex justify-center"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.5 }}
    >
      <ChevronDown size={36} className="text-white animate-bounce" />
    </motion.div>
        </motion.section>
        )}

        {/* Search Section */}
        <section className="mb-16">
          <div className="max-w-3xl mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Liste veya kategori ara..."
                className="w-full py-5 px-8 rounded-full bg-white shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 text-lg"
              />
              <button className="absolute right-3 top-1/2 transform -translate-y-1/2 bg-indigo-600 text-white p-3 rounded-full hover:bg-indigo-700 transition-colors duration-300 shadow-md">
                <Search size={24} />
              </button>
            </div>
          </div>
        </section>

        {/* Featured Lists */}
        <motion.section 
          className="mb-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h2 className="text-4xl font-bold text-white mb-8 text-center">Öne Çıkan Listeler</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {featuredLists.map((list, index) => (
              <motion.div
                key={list.id}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <ListItem
                    id={list.id}
                    title={list.title}
                    itemCount={list.itemCount}
                    isPrivate={list.isPrivate}
                    category={list.category}
                    onClick={() => handleSelectList(list.id)}
                  />
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Categories Section */}
        <CategoriesSection />

        {/* Recent Lists */}
        <section className="mb-20">
          <h2 className="text-4xl font-bold text-white mb-12 text-center">Son Eklenen Listeler</h2>
          {categories.map((category) => {
            const lists = categoryLists[category.name] || [];
            if (lists.length === 0) return null;

            return (
              <motion.div 
                key={category.name} 
                className="mb-16"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="flex justify-between items-center mb-6">
                  <h3 className="text-2xl font-semibold text-white flex items-center">
                    <div className="bg-white p-2 rounded-full mr-3">
                      {category.icon}
                    </div>
                    <span>{category.name}</span>
                  </h3>
                  <motion.button 
                  onClick={() => navigate(`/category/${toTitleCase(category.name)}`)}
                  className="group flex items-center text-white bg-gradient-to-r from-purple-700 via-purple-600 to-indigo-500 hover:from-purple-800 hover:via-purple-700 hover:to-indigo-600 py-2 px-6 rounded-full shadow-md hover:shadow-lg transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span className="mr-2 font-medium">Tümünü Gör</span>
                  <ArrowRight size={20} className="transform group-hover:translate-x-1 transition-transform duration-300" />
                </motion.button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                  {lists.map((list, index) => (
                    <motion.div
                      key={list.id}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                    >
                      <ListItem
                        id={list.id}
                        title={list.title}
                        itemCount={list.itemCount}
                        isPrivate={list.isPrivate}
                        category={list.category}
                        onClick={() => handleSelectList(list.id)}
                      />
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            );
          })}
        </section>

        {/* Stats Section */}
        {!isLoggedIn && (
          <motion.section 
            className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl p-12 mb-16 shadow-2xl"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h2 className="text-4xl font-bold text-white mb-12 text-center">Listart İstatistikleri</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
              <motion.div 
                className="flex flex-col items-center bg-indigo-600 bg-opacity-50 p-8 rounded-2xl shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <TrendingUp size={64} className="text-white mb-6" />
                <span className="text-5xl font-bold text-white mb-2">10,000+</span>
                <span className="text-xl text-gray-100">Oluşturulan Liste</span>
              </motion.div>
              <motion.div 
                className="flex flex-col items-center bg-purple-600 bg-opacity-50 p-8 rounded-2xl shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <Users size={64} className="text-white mb-6" />
                <span className="text-5xl font-bold text-white mb-2">5,000+</span>
                <span className="text-xl text-gray-100">Aktif Kullanıcı</span>
              </motion.div>
              <motion.div 
                className="flex flex-col items-center bg-pink-600 bg-opacity-50 p-8 rounded-2xl shadow-lg"
                whileHover={{ scale: 1.05 }}
              >
                <Star size={64} className="text-white mb-6" />
                <span className="text-5xl font-bold text-white mb-2">50,000+</span>
                <span className="text-xl text-gray-100">Liste Öğesi</span>
              </motion.div>
            </div>
          </motion.section>
        )}

        {/* Call to Action */}
        {!isLoggedIn && (
        <motion.section
          className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-3xl p-12 mb-16 shadow-2xl text-center relative overflow-hidden"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-br from-purple-600 to-pink-600 opacity-50 z-0"></div>
          <div className="relative z-10">
            <h2 className="text-4xl font-bold text-white mb-6">Kendi Listelerinizi Oluşturmaya Başlayın</h2>
            <p className="text-xl text-gray-100 mb-8 max-w-2xl mx-auto">
              Hemen ücretsiz hesap oluşturun ve listelerinizi paylaşmaya başlayın. Listart ile organize olmanın keyfini çıkarın!
            </p>
            <motion.button 
              className="bg-white text-indigo-600 px-8 py-4 rounded-full font-semibold text-lg hover:bg-indigo-100 transition-all duration-300 transform hover:scale-105 shadow-lg"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleStartNow}
            >
              Ücretsiz Hesap Oluştur
            </motion.button>
          </div>
        </motion.section>
        )}

        {/* Testimonials Section */}
        {!isLoggedIn && (
        <section className="mb-20">
          <h2 className="text-4xl font-bold text-white mb-12 text-center">Kullanıcılarımız Ne Diyor?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { name: "Ayşe Y.", comment: "Listart ile hayatım çok daha düzenli! Artık hiçbir şeyi unutmuyorum." },
              { name: "Mehmet K.", comment: "Arkadaşlarımla film listelerimi paylaşmak çok keyifli. Harika bir platform!" },
              { name: "Zeynep S.", comment: "Seyahat planlarımı Listart ile yapmak çok kolay. Kesinlikle tavsiye ediyorum." }
            ].map((testimonial, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <p className="text-white mb-4">"{testimonial.comment}"</p>
                <p className="text-indigo-200 font-semibold">{testimonial.name}</p>
              </motion.div>
            ))}
          </div>
        </section>
        )}

        {/* FAQ Section */}
        {!isLoggedIn && (
        <section className="mb-20">
          <h2 className="text-4xl font-bold text-white mb-12 text-center">Sıkça Sorulan Sorular</h2>
          <div className="space-y-6">
            {[
              { question: "Listart nedir?", answer: "Listart, kullanıcıların çeşitli kategorilerde listeler oluşturabileceği, paylaşabileceği ve keşfedebileceği bir platformdur." },
              { question: "Listart'ı nasıl kullanabilirim?", answer: "Ücretsiz bir hesap oluşturarak hemen kullanmaya başlayabilirsiniz. Listelerinizi oluşturun, düzenleyin ve isterseniz başkalarıyla paylaşın." },
              { question: "Listelerimi özel tutabilir miyim?", answer: "Evet, listelerinizi özel veya herkese açık olarak ayarlayabilirsiniz. Özel listelerinizi sadece siz görebilirsiniz." }
            ].map((faq, index) => (
              <motion.div
                key={index}
                className="bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl p-6 shadow-lg"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
                <h3 className="text-xl font-semibold text-white mb-2">{faq.question}</h3>
                <p className="text-indigo-100">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </section>
        )}
      </main>

      {/* Floating Action Button */}
      {/* {isLoggedIn && (
        <motion.button 
          className="fixed bottom-8 right-8 bg-white text-indigo-600 p-4 rounded-full shadow-lg hover:bg-indigo-100 transition-colors duration-300 flex items-center justify-center"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Plus size={24} />
          <span className="ml-2 font-semibold">Yeni Liste</span>
        </motion.button>
      )} */}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default HomePage;